import { useEffect, useState } from 'react';
import { CONTENTFUL_TYPES } from 'views/blog-post/constants';
import { createClient } from 'contentful';
import { navigate } from 'gatsby';

const space = process.env.GATSBY_CONTENTFUL_SPACE_ID;
const accessToken = process.env.GATSBY_CONTENTFUL_PREVIEW_TOKEN;

const convertToJSLiteral = (string) => {
    const escapedString = string
        .replace(/\\/g, '\\\\')
        .replace(/'/g, "\\'")
        .replace(/"/g, '\\"')
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r')
        .replace(/\t/g, '\\t')
        .replace(/\\'/g, "'");
    return `'${escapedString}'`;
};

// Replicate Delivery API raw code string structure
const convertToRawCode = (str) => {
    const prefix =
        '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"';
    const suffix = '","marks":[{"type":"code"}],"data":{}}]}]}';
    return `${prefix}${convertToJSLiteral(str)}${suffix}`;
};

export const usePreviewBlogPostData = ({ slug, allowAccess }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchBlogPost = async () => {
            if (!allowAccess) {
                if (typeof window !== 'undefined') {
                    navigate('/page-not-found');
                }
                return;
            }

            try {
                const client = createClient({
                    space,
                    accessToken,
                    host: 'preview.contentful.com',
                });
                const response = await client.getEntries({
                    content_type: 'blogPost',
                    'fields.slug': slug,
                    include: 10,
                });

                const previewBlogPost = response.items[0].fields;
                const previewCodeEntries = response.includes.Entry.filter(
                    (e) => e?.sys?.contentType?.sys?.id === 'codeSnippet',
                );

                const previewCodeEntriesReferences = previewCodeEntries.map(
                    (item) => {
                        return {
                            code: {
                                raw: convertToRawCode(
                                    item?.fields?.code?.content[0]?.content[0]
                                        ?.value,
                                ),
                            },
                            contentful_id: item?.sys?.id,
                            language: item?.fields?.language,
                            title: item?.fields?.title,
                            internal: {
                                type: CONTENTFUL_TYPES.CODE_SNIPPET,
                            },
                        };
                    },
                );

                const previewCtaBlogPosts = response.includes.Entry.filter(
                    (e) => e?.sys?.contentType?.sys?.id === 'ctaBlogPost',
                );
                const previewCtaBlogPostsReferences = previewCtaBlogPosts.map(
                    (item) => {
                        return {
                            ...item.fields,
                            contentful_id: item?.sys?.id,
                            internal: {
                                type: CONTENTFUL_TYPES.CTA_BLOG_POST,
                            },
                        };
                    },
                );

                const previewCtaBlogPostsExtended =
                    response.includes.Entry.filter(
                        (e) =>
                            e?.sys?.contentType?.sys?.id ===
                            'ctaBlogPostExtended',
                    );

                const previewCtaBlogPostsExtendedReferences =
                    previewCtaBlogPostsExtended.map((item) => {
                        return {
                            contentful_id: item?.sys?.id,
                            headline: item?.fields?.headline,
                            content: {
                                raw: JSON.stringify(
                                    item?.fields?.content?.content[0],
                                ),
                            },
                            internal: {
                                type: CONTENTFUL_TYPES.CTA_BLOG_POST_EXTENDED,
                            },
                        };
                    });

                const previewImageLinks = response.includes.Entry.filter(
                    (e) => e?.sys?.contentType?.sys?.id === 'imageLink',
                );
                const previewImageLinksReferences = previewImageLinks.map(
                    (item) => {
                        return {
                            contentful_id: item?.sys?.id,
                            url: item?.fields?.url,
                            rel: item?.fields?.rel,
                            image: {
                                file: {
                                    fileName:
                                        item?.fields?.image?.fields?.title,
                                },
                                description:
                                    item?.fields?.image?.fields?.description,
                                url: item?.fields?.image?.fields?.file?.url
                                    ? `https:${item.fields.image.fields.file.url}`
                                    : undefined,
                            },
                            internal: {
                                type: CONTENTFUL_TYPES.IMAGE_LINK,
                            },
                        };
                    },
                );

                const previewNFTRealityBanners = response.includes.Entry.filter(
                    (e) => e?.sys?.contentType?.sys?.id === 'nftRealityBanner',
                );

                const previewNFTRealityBannersReferences =
                    previewNFTRealityBanners.map((item) => {
                        return {
                            contentful_id: item?.sys?.id,
                            h1Part1: item?.fields?.h1Part1,
                            h1Part2: item?.fields?.h1Part2,
                            leadText: {
                                raw: JSON.stringify(
                                    item?.fields?.leadText?.content[0],
                                ),
                            },
                            internal: {
                                type: CONTENTFUL_TYPES.NFT_BANNER,
                            },
                        };
                    });

                const previewListOfArticles = response.includes.Entry.filter(
                    (e) =>
                        e?.sys?.contentType?.sys?.id ===
                        'relatedListOfArticles',
                );

                const previewListOfArticlesReferences =
                    previewListOfArticles.map((item) => {
                        return {
                            contentful_id: item?.sys?.id,
                            list: item?.fields?.list.map((field) => {
                                return {
                                    title: field.fields.title,
                                    slug: field.fields.slug,
                                };
                            }),
                            internal: {
                                type: CONTENTFUL_TYPES.LIST_OF_ARTICLES,
                            },
                        };
                    });

                const previewInsightsRelatedToTopics =
                    response.includes.Entry.filter(
                        (e) =>
                            e?.sys?.contentType?.sys?.id ===
                            'insightsRelatedToTopic',
                    );

                const previewInsightsRelatedToTopicsReferences =
                    previewInsightsRelatedToTopics.map((item) => {
                        return {
                            contentful_id: item?.sys?.id,
                            title: item?.fields?.title,
                            content: {
                                raw: item?.fields?.content?.content,
                                references: [
                                    ...previewListOfArticlesReferences,
                                ],
                            },
                            internal: {
                                type: CONTENTFUL_TYPES.RELATED_TO_TOPIC,
                            },
                        };
                    });

                const previewEmbeddedAssetsReferences =
                    response.includes.Asset.map((item) => {
                        return {
                            contentful_id: item?.sys?.id,
                            title: item?.fields?.title,
                            description: item?.fields?.description,
                            url: item?.fields?.file?.url
                                ? `https:${item.fields.file.url}`
                                : undefined,
                            internal: {
                                type: CONTENTFUL_TYPES.ASSET,
                            },
                        };
                    });

                setData({
                    ...previewBlogPost,
                    previewReferences: [
                        ...previewCodeEntriesReferences,
                        ...previewCtaBlogPostsReferences,
                        ...previewImageLinksReferences,
                        ...previewNFTRealityBannersReferences,
                        ...previewInsightsRelatedToTopicsReferences,
                        ...previewEmbeddedAssetsReferences,
                        ...previewListOfArticlesReferences,
                        ...previewCtaBlogPostsExtended,
                        ...previewCtaBlogPostsExtendedReferences,
                    ],
                });
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error('Error fetching previewed blog post:', err);
            }
        };

        fetchBlogPost();
    }, [slug]);

    return { data };
};
